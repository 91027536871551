<template>
  <section class="services mt-11">
    <div class="container mx-auto">
      <div class="info mb-5">
        <h2 class="text-4xl font-bold text-primary mb-5">
          {{ items.title }}
        </h2>
        <p class="text-gray-500">
          {{ items.description }}
        </p>
      </div>
      <div class="mt-11 features">
        <div class="feature" v-for="item in items.service" :key="item.id">
          <div class="content">
            <div class="icon relative text-primary inline-block">
              <img :src="item.image" alt="erp" />
            </div>
            <div class="p-2">
              <h5 class="text-black text-2xl mt-5 mb-4">{{ item.title }}</h5>
              <p class="text-gray-600">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['items'],
}
</script>

<style lang="scss" scoped>
.services {
  padding: 50px 0;
  border: none !important;
  .info {
    max-width: 80%;
    margin: 0 auto 50px;
    text-align: center;
    h2 {
      width: 66%;
      margin: auto;
      text-align: center;
      line-height: 50px;
      text-shadow: 0px 1px 1px #333;
    }
  }
  .features {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .feature {
      width: 100%;
      padding: 10px;
      text-align: center;
      .content {
        position: relative;
        transition: 0.3s;
        &::before {
          content: '';
          position: absolute;
          transition: 0.3s;
          bottom: -4px;
          left: 50%;
          transform: translateX(-50%);
          height: 4px;
          width: 0;
          @apply bg-secondary;
        }
        &:hover {
          transform: translateY(-10px);
          cursor: pointer;
          &::before {
            width: 100%;
          }
        }
        padding: 20px;
        background: #fff;
      }
      .icon {
        &::before {
          background: rgba(47, 85, 212, 0.1);
          animation: spinner 5s linear infinite !important;
        }
      }

      .icon {
        img {
          margin: 0 5px 13px;
          width: 100px;
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 5px;
          left: 5px;
          width: 100px;
          height: 100px;
          border-radius: 6px;
          background: linear-gradient(
            45deg,
            transparent,
            rgba(47, 85, 212, 0.1)
          );
          h5 {
            line-height: 1.4;
          }
          p {
            line-height: 1.6;
          }
          transform: rotate(33.75deg);
        }
      }
      @media (min-width: 768px) {
        width: 33%;
      }
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(33.75deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-rtl {
  .features {
    .feature {
      .icon {
        &::before {
          left: unset;
          right: 5px;
        }
      }
    }
  }
}
</style>
