export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رواد CRM  برنامج ادارة علاقات العملاء"])},
  "locales": {
    "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربي"])}
  },
  "nav": {
    "Getstarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبدا الان"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملائنا"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركائنا"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المميزات"])}
  },
  "misc": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباسورد"])},
    "powerd by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كافة الحقوق محفوظة لشركه"])},
    "technomasr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكنو مصر للبرمجيات"])},
    "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط"])},
    "free trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحصل علي نسختك التجريبيه"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركائنا"])},
    "Phone number verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التأكد من رقم الموبايل"])},
    "Enter the code sent to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الكود المرسل لـ"])},
    "Enter your data to get your free trial copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بياناتك للحصول علي نسختك التجريبية"])},
    "your free trial details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات نسختك التجريبية"])},
    "start now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إبدأ الان"])},
    "Didnt receive the code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألم تستلم الكود؟"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملائنا"])},
    "introTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رواد CRM - برنامج ادارة علاقات العملاء"])},
    "introDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوف يساعدك رواد CRM في تسجيل وحفظ بيانات العملاء بشكل منظم وتفصيلي وتسهيل عملية شراء بشكل احترافي وسرعة التواصل معهم وبالتالي زيادة المبيعات. حيث يعمل نظام رواد CRM على تصنيف كل مرحلة من مراحل البيع حسب احتياج العميل مع تواجد خدمة عملاء ليسجلوا ماذا يحدث مع العميل بالتفصيل. وبامكانك ايضا امكانية ربط النظام عن طريق تطبيقات للاندرويد والايفون ليسهل على المستخدمين امكانية الحصول على احتياجهم في اي وقت واي مكان بحيث يتوافر عضوية لكل مسئول على حسب الوظيفة الخاصة به في الشركة."])}
  },
  "buttons": {
    "Start Now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إبدأ الأن"])},
    "RESEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة إرسال"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
  },
  "placeholder": {
    "crmUsingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما طبيعة نشاطك"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني "])},
    "verfication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التفعيل"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال /1069697998"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الدولة"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عدد الموظفين المستخدمين لرواد CRM"])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا !!"])}
}