export default {
  "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Rwad CRM"]), _normalize(["Client relations system"])])},
  "locales": {
    "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربي"])}
  },
  "nav": {
    "Getstarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])}
  },
  "buttons": {
    "Start Now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Now"])},
    "RESEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESEND"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERIFY"])}
  },
  "misc": {
    "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "powerd by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved to"])},
    "technomasr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technomasr"])},
    "We are happy to provide our services to clients who were our success partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are happy to provide our services to clients who were our success partners"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "free trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your free trial"])},
    "Invalid Phone Number Format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Phone Number Format!"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our partners"])},
    "Phone number verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number verification"])},
    "Enter the code sent to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code sent to"])},
    "your free trial details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your free trial details"])},
    "Didnt receive the code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive the code?"])},
    "Enter your data to get your free trial copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your data to get your free trial copy"])},
    "start now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start now"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our clients"])},
    "introTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RWAD CRM - Customer Relationship Management Software"])},
    "introDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RWAD CRM will help you in recording and saving customer data in an organized and detailed manner, facilitating the purchase process in a professional manner, and quickly communicating with them, thus increasing sales. Where the RWAD CRM system works to classify each stage of the sale according to the customer's need with the presence of customer service to record what happens with the customer in detail. And you can also connect the system through applications for Android and iPhone to make it easier for users to obtain their needs at any time and any place so that membership is available for each official according to his job in the company."])}
  },
  "placeholder": {
    "crmUsingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRM using for"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
    "verfication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfication code"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of employers using Rwad CRM"])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}