<template>
  <section class="applications px-4">
    <div class="container mx-auto">
      <h2
        v-html="items.title"
        class="text-2xl application md:text-4xl font-bold mb-4 text-primary"
      ></h2>
      <p class="text-gray-600 text-lg md:text-xl">
        {{ items.description }}
      </p>
      <div class="lists flex justify-center flex-wrap">
        <div
          class="list w-full md:w-1/2 lg:w-1/3 flex"
          v-for="application in items.application"
          :key="application.id"
        >
          <div
            class="icon mt-2"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
          >
            <img :src="application.image" alt="erp" />
          </div>
          <div class="info mb-5 flex-1">
            <h3 class="mb-2 text-2xl text-primary font-bold">
              {{ application.title }}
            </h3>
            <p class="text-gray-600">
              {{ application.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['items'],
}
</script>

<style lang="scss">
h2.application {
  span {
    @apply text-secondary;
    text-transform: uppercase;
  }
}
.applications {
  min-height: calc(80vh - 80px);
  padding-top: 100px;
  .container {
    & > h2 {
      width: 66%;
      margin: auto;
      text-align: center;
      line-height: 50px;
      text-shadow: 0px 1px 1px #333;
    }
    .lists {
      margin-top: 100px;
      .list {
        margin-bottom: 70px;
        padding: 0 15px;
        .icon {
          width: 100px;
          height: auto;
          img {
            width: 100%;
          }
        }
        .info {
          & > p {
            font-weight: 600;
            width: 93%;
          }
        }
      }
    }
  }
}
</style>
