<template>
  <div class="gotop">
    <a href="https://wa.me/+01069697998" target="_blank">
      <font-awesome-icon :icon="['fab', 'whatsapp']" />
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gotop: false,
    }
  },
  created() {
    window.addEventListener('scroll', this.checkScroll)
  },
  methods: {
    checkScroll() {
      if (window.scrollY > 400) {
        this.gotop = true
        return
      }
      this.gotop = false
      return
    },
  },
}
</script>

<style lang="scss">
.gotop {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #27d246;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 25px;
  color: #fff;
  // animation: bunceing 3s linear infinite;
}
// @keyframes bunceing {
//   0%,
//   20%,
//   40% {
//     transform: translateY(0px);
//   }
//   60% {
//     transform: translateY(10px);
//   }
//   100% {
//     transform: translateY(0);
//   }
// }

.is-rtl {
  .gotop {
    position: fixed;
    bottom: 50px;
    left: 50px;
    right: unset !important;
  }
}
</style>
