<template>
  <section id="clients" class="clients scrollme" v-if="items">
    <h2 class="text-center title text-4xl font-bold pt-5 mb-3">
      عملاء نفتخر بهم
    </h2>
    <!-- <p class="text-gray-500 text-center">عملاء نفتخر بهم</p> -->
    <div class="flex items-center">
      <div
        class="container flex flex-wrap justify-center mx-auto text-white pb-11"
      >
        <div
          class="image p-2 w-1/2 md:w-1/3 xl:w-2/12 mb-11"
          v-for="item in items"
          :key="item.id"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
        >
          <img v-if="item.image" class="mx-auto" :src="item.image" alt="Erp" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['items'],
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.clients {
  min-height: 200px;
  position: relative;

  background: #f6f6f7;
  .container {
    z-index: 11;
    .image {
      width: 180px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 20px;
      overflow: hidden;
    }
    > p {
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
}

h2.title {
  position: relative;
  margin-bottom: 50px;
  color: #004972;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 130px;
    height: 2px;
    bottom: -17px;
    @apply bg-primary;
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    bottom: -23px;
    border: 2px solid;
    background: #fff;
  }
}
</style>
