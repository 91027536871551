<template>
  <footer>
    <div class="logo" v-if="logo">
      <img class="mx-auto" :src="logo.image" alt="erp" />
    </div>
    <div>
      <ul class="flex justify-center flex-wrap">
        <li class="ml-4">
          <a href="mailto:info@technomasr.com" class="text-white text-sm">
            info@technomasr.com
            <font-awesome-icon :icon="['fas', 'envelope']" />
          </a>
        </li>
        <li class="ml-4">
          <a href="tel:+01097225751" class="text-white text-sm">
            01097225751
            <font-awesome-icon :icon="['fas', 'phone']" />
          </a>
        </li>
        <li>
          <a href="tel:+01069697998" class="text-white text-sm">
            01069697998
            <font-awesome-icon :icon="['fas', 'phone']" />
          </a>
        </li>
      </ul>
    </div>
    <div class="copyright">
      <p class="text-sm md:text-lg">
        {{ $t('misc.powerd by') }}
        <a href="https://technomasr.com/" target="_blank">
          {{ $t('misc.technomasr') }}
        </a>
        &copy;
        {{ year }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: null,
      logo: null,
    }
  },
  mounted() {
    this.getYear()
    this.getSettings()
  },
  methods: {
    getYear() {
      this.year = new Date().getFullYear()
    },
    getSettings() {
      this.axios.get('lists').then((data) => {
        this.logo = data.data.lists.logo
      })
    },
  },
}
</script>

<style scoped lang="scss">
footer {
  min-height: 50px;
  background-color: #232323;
}
footer .copyright p {
  color: #fff;
  padding: 10px 0;
  text-align: center;
  a {
    color: #f8ac09;
  }
}
.logo {
  padding-top: 20px;
  img {
    height: 150px;
    filter: invert(1) brightness(100);
  }
}
</style>
