<template>
  <section class="relative dashboard bg-soft-primary">
    <div class="image dash" v-if="dash.image">
      <div class="container mx-auto">
        <img class="mx-auto dash rounded-xl" :src="dash.image" alt="Erp" />
      </div>
    </div>
    <div v-if="items">
      <section class="face px-4" v-for="item in items" :key="item.id">
        <div class="container mx-auto">
          <div class="info flex justify-between flex-wrap">
            <div class="image px-4 w-full lg:w-1/2" v-if="item.image">
              <img class="mx-auto" :src="item.image" alt="Erp" />
            </div>
            <div
              class="text w-full flex mt-11 lg:mt-0 flex-col justify-center px-4"
              :class="item.image ? ' lg:w-1/2' : ''"
            >
              <h2 class="text-primary mb-11 font-bold text-2xl">
                {{ item.title }}
              </h2>
              <p class="text-lg text-gray-600">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  props: ['items', 'dash'],
}
</script>

<style scoped lang="scss">
section.dashboard {
  // padding-top: 100px;
  // min-height: calc(100vh - 80px);
  border: none !important;

  // @media (max-width: 992px) {
  //   min-height: calc(50vh - 80px);
  // }

  .image {
    img.dash {
      box-shadow: 0 15px 25px rgba(60, 72, 88, 0.15) !important;
    }
    &.dash {
      position: relative;
      padding-top: 50px;
      margin-bottom: 100px;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: #2f55d4 !important;
        z-index: -1;
      }
    }
  }
  .bgoverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background-color: #2f55d4 !important;
    z-index: -1;
    @media (min-width: 1200px) {
      height: 60%;
    }
  }
}

section.face {
  .container {
    .info {
      padding: 50px 0;
    }
  }
  &:nth-child(odd) {
    background-color: #fff;
    // background-color: rgba(47, 85, 212, 0.1) !important;
    .container {
      .info {
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
