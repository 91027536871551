<template>
  <section id="features" class="section" v-for="item in items" :key="item.id">
    <div class="container mx-auto flex justify-between flex-wrap">
      <div class="image w-full md:w-1/2 p-5" v-if="item.image">
        <img :src="item.image" alt="ERP" />
      </div>
      <div class="info w-full p-5" :class="item.image ? 'md:w-1/2' : ''">
        <h2 class="mb-5 text-2xl md:text-4xl font-bold text-primary">
          {{ item.title }}
        </h2>
        <p class="mb-11 text-xl md:text-2xl text-gray-600">
          {{ item.description }}
        </p>
        <div class="features">
          <div
            class="feat"
            v-for="feature in item.featureList"
            :key="feature.id"
          >
            <p class="text-gray-800 text-lg font-bold">
              <span
                :class="[
                  $i18n.locale == 'ar' ? 'ml-2' : 'mr-2',
                  `${feature.icon}`,
                ]"
              ></span>
              {{ feature.title }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['items'],
}
</script>

<style scoped lang="scss">
.section {
  padding: 50px 0;
  min-height: calc(100vh - 80px);

  &:nth-child(odd) {
    background-color: rgba(47, 85, 212, 0.1) !important;
    .container {
      flex-direction: row-reverse;
    }
  }
  .image {
    img {
      width: 80%;
      margin: 0 auto;
    }
  }
  .info {
    p {
      line-height: 1.4;
    }
  }
  .features {
    .feat {
      margin-bottom: 10px;
      img {
        width: 26px;
      }
    }
  }
}
</style>
