<template>
  <Loading v-if="loading" />
  <div v-else>
    <intro v-if="header" :items="header" />
    <Applications v-if="applications" :items="applications" />
    <Services class="bg-soft-primary" v-if="services" :items="services" />
    <Subscripe v-if="subscrition" :items="subscrition" />
    <Content v-if="mainFeatures" :items="mainFeatures" />
    <Dashboard
      v-if="dashboardInfo"
      :dash="dashboardInfo"
      :items="dashFeatures"
    />
    <get-started />

    <Clients v-if="clients" :items="clients" />
    <go-top />
  </div>
</template>

<script>
import Intro from '@/components/Intro.vue'
import GoTop from '@/components/GoTop.vue'
import Services from '@/components/Services.vue'
import Content from '@/components/Content.vue'
import Subscripe from '@/components/Subscripe.vue'
import Dashboard from '../components/Dashboard.vue'
import Clients from '@/components/Clients.vue'
import Applications from '@/components/Applications.vue'
import Loading from '../components/Loading.vue'

export default {
  components: {
    Intro,
    GoTop,
    Services,
    Content,
    Subscripe,
    Dashboard,
    Clients,
    Applications,
    Loading,
  },
  data() {
    return {
      loading: false,
      services: null,
      applications: null,
      dashboardInfo: null,
      features: null,
      clients: null,
      header: null,
      mainFeatures: null,
      dashFeatures: null,
    }
  },
  methods: {
    getSettings() {
      this.loading = true
      this.axios
        .get('lists')
        .then((data) => {
          this.services = data.data.lists.services
          this.applications = data.data.lists.applications
          this.subscrition = data.data.lists.subscrition
          this.dashboardInfo = data.data.lists.dashboard
          this.header = data.data.lists.home
          this.clients = data.data.lists.clients
          this.mainFeatures = data.data.lists.featuresTop
          this.dashFeatures = data.data.lists.featuresBottom
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  mounted() {
    this.getSettings()
  },

  computed: {
    locale() {
      return this.$store.getters.locale
    },
  },
}
</script>

<style></style>
