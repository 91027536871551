<template>
  <section class="banner bg-soft-primary flex" id="banner">
    <free-trial v-if="trial" @modal="modal" />
    <div class="container mx-auto flex justify-between items-center flex-wrap">
      <div
        class="info p-5 xl:px-11 mb-11 w-full"
        data-aos="zoom-in"
        data-aos-duration="1500"
        :class="items.image ? ' lg:w-1/2' : ''"
      >
        <h1 class="mt-5 text-primary">
          {{ items.title }}
        </h1>
        <p class="">
          {{ items.description }}
        </p>

        <div class="mt-11">
          <router-link
            to="/register"
            class="bg-primary mt-4 rounded px-4 py-2 text-white hover:bg-secondary"
          >
            {{ $t('misc.free trial') }}
          </router-link>
        </div>
      </div>
      <div
        v-if="items.image"
        class="image w-full lg:w-1/2 p-4"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <img :src="items.image" class="w-full" alt="rwad-header" />
      </div>
    </div>
  </section>
</template>

<script>
import FreeTrial from '@/components/FreeTrial.vue'
export default {
  props: ['items'],
  components: { FreeTrial },
  data() {
    return {
      trial: false,
    }
  },

  methods: {
    modal() {
      this.trial = !this.trial
    },
  },
}
</script>

<style scoped lang="scss">
a {
  transition: 0.3s;
}
.banner {
  padding-top: 50px;
  height: 650px;
  overflow: hidden;
  .container {
    .info {
      text-align: center;
      @media (min-width: 768px) {
        text-align: start;
      }
      h1 {
        margin-bottom: 24px;
        line-height: 1.2;
        font-weight: 700;
        font-size: 29px;
        text-shadow: 0px 1px 1px #333;
      }
      p {
        color: #53575a;
        font-size: 18px;
        line-height: 33px;
        font-weight: 600;
      }
      @media (max-width: 992px) {
        padding-top: 150px;
        h1 {
          // font-size: 40px;
          max-width: 100%;
        }
        p {
          max-width: 100%;
        }
      }
    }
    .image {
      img {
        width: 90%;
        margin-right: auto;
      }
    }
  }
}

@keyframes bunce {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
</style>
