<template>
  <section
    class="subscripe"
    :style="`background: url(${items.image}) no-repeat fixed;`"
  >
    <div
      class="container p-5 mx-auto flex justify-between flex-wrap items-center"
    >
      <div class="w-full md:w-3/4">
        <h2 class="text-2xl font-bold text-white">
          {{ items.title }}
        </h2>
        <p class="text-md mt-8 text-white">
          {{ items.description }}
        </p>
        <p class="mt-4 text-white text-lg">
          <span>
            <font-awesome-icon size="sm" :icon="['fas', 'phone']" />
            <a href="tel:+01069697998" class="text-white contact">
              01069697998
            </a>
          </span>
          <span>
            <font-awesome-icon :icon="['fas', 'phone']" />
            <a href="tel:+01097225751" class="text-white contact">
              01097225751
            </a>
          </span>
        </p>
      </div>
      <div class="w-full md:w-1/4">
        <a
          href="/register"
          class="bg-secondary px-5 py-4 rounded text-white font-bold"
        >
          إبدا الاستخدام مجانا
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['items'],
}
</script>

<style scoped lang="scss">
.subscripe {
  background-size: cover !important;
  background-position: center center !important;
  min-height: 300px;
  position: relative;
  overflow: hidden;
  display: flex;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(3 48 75 / 48%);
  }
  .container {
    text-align: center;
    // border-radius: 30px;
    @media (min-width: 768px) {
      text-align: start;
    }
    & > div {
      z-index: 10;
    }
  }
}
// .contact:first-child {
//   margin: 0 !important;
// }

a.contact {
  font-size: 30px;
  margin: 0px 0px 0px 15px;
  text-shadow: 3px 2px 2px #333;
}
h2 {
  font-size: 35px;
  text-shadow: 3px 2px 2px #333;
}
p {
  text-shadow: 3px 2px 2px #333;
  font-size: 21px;
  padding-left: 30px !important;
}
</style>
